import './App.css';
import './styles/fontawesome-free-6.5.2-web/css/all.css';
import React, { useState, useEffect } from "react";
import Skills from "./skills.json"
import Emotions from "./emotions.json"
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [emotionsList, setEmotionsList] = useState("");
  const [newEmotionName, setNewEmotionName] = useState("");
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [queryIndex, setQueryIndex] = useState("");
  const [edit, setEdit] = useState("");
  const [skillOptions, setSkillOptions] = useState("");
  const [emotionOptions, setEmotionOptions] = useState("");
  const [showToast, setShowToast] = useState("");

  function getElementbyName(name, myArray){
    for (var i = 0; i < myArray.length; i++){
      if (myArray[i].name === name){
        return [myArray[i], i]
      }
    }
    return [false, false]
  }

  useEffect(() => {
    function combineJSON(Emotions, Skills){
      for (var i = 0; i < Emotions.length; i++){
        let skills_list = []
        if (i === 0){
          skills_list = Skills
        } else {
          for (var j = 0; j < Emotions[i].skills.length; j++){
            skills_list.push(getElementbyName(Emotions[i].skills[j], Skills)[0])
          }
        }
        Emotions[i].skills = skills_list
      }
      return Emotions
    }
    if (localStorage.getItem('emotions') === null) {
      // if no local storage, combine json files
      combineJSON(Emotions, Skills)
      localStorage.setItem('emotions', JSON.stringify(Emotions));
      setShowToast(true)
    } else {
      // if local storage exists, update all skills by name from Skills json
      let emotions = JSON.parse(localStorage.getItem('emotions'))
      for (var i = 0; i < emotions.length; i++){
        if (i === 0){
          emotions[i].skills = Skills
        }
        emotions[i].skills = emotions[i].skills.map((skill) => {return getElementbyName(skill.name, Skills)[0]})
      }
      localStorage.setItem('emotions', JSON.stringify(emotions));
      setShowToast(false)
    }
    let emotions = JSON.parse(localStorage.getItem('emotions'))
    setEmotionsList(emotions)
    setQuery(emotions[0])
    setQueryIndex(0)
    setEdit(false)
    let emotionNames = emotions.map(emotion=>emotion.name)
    emotionNames.push("add emotion")
    emotionNames.shift()
    setEmotionOptions(emotionNames)
  }, []);

  function findUnusedSkills(emotion){
    let skill_names = Skills.map(skill => skill.name)
    let current_skill_names = emotion.skills.map(skill => skill.name)
    const diff = skill_names.filter(x => !current_skill_names.includes(x));
    return diff
  }

  function selectEmotion(name){
    setSearch(name)
    if (name === "add emotion"){
      // if add emotion is selected, set query to -1
      setQueryIndex(-1)
    } else {
      // when emotion is selected, get emotion and set query
      const [emotion, idx] = getElementbyName(name, emotionsList)
      if (!!emotion){
        setQuery(emotion)
        setQueryIndex(idx)
        if (edit === true){
          setEdit(!edit)
        }
        setSkillOptions(findUnusedSkills(emotion))
      }
    }
  }

  function addEmotion(){
    if (newEmotionName.trim() === "") {
      toast.error("Cannot leave field blank.", {theme: "dark"})
    } else if (emotionOptions.includes(newEmotionName)){
      toast.error("Emotion already exists.", {theme: "dark"})
    } else {
      // when add emotion, make new object and add to list
      const newEmotion = {
        "id": emotionsList.slice(-1)[0].id + 1,
        "name": newEmotionName,
        "skills": []
      };
      emotionsList.splice(1, 0, newEmotion)
      localStorage.setItem("emotions", JSON.stringify(emotionsList))
      let newOptions = emotionOptions.slice()
      newOptions.unshift(newEmotionName)
      setEmotionOptions(newOptions)
      setNewEmotionName("") // reset input field
    }
  }

  function deleteEmotion(name){
    const emotion_idx = getElementbyName(name, emotionsList)[1]
    emotionsList.splice(emotion_idx, 1)
    localStorage.setItem("emotions", JSON.stringify(emotionsList))
    let newOptions = emotionOptions.slice()
    newOptions.splice(emotion_idx-1, 1)
    setEmotionOptions(newOptions)
  }

  function addSkill(name){
    if (!!query){
      const skill = getElementbyName(name, Skills)[0]
      if (Skills.includes(skill) && !query.skills.includes(skill)){
        query.skills.unshift(skill)
        setSkillOptions(findUnusedSkills(query))
        emotionsList[queryIndex] = query;
        localStorage.setItem("emotions", JSON.stringify(emotionsList))
      }
    }
  }

  function deleteSkill(name){
    if (!!query){
      const skills_idx = getElementbyName(name, query.skills)[1]
      query.skills.splice(skills_idx, 1)
      setSkillOptions(findUnusedSkills(query))
      emotionsList[queryIndex] = query;
      localStorage.setItem("emotions", JSON.stringify(emotionsList))
    }
  }

  function save(){
    if (showToast){
      toast.info("Changes will not be saved on private or incognito browsers.", {theme: "dark", autoClose: false})
    }
    setShowToast(false)
    setEdit(!edit)
  }

  const dragEnded = (param) => {
    const { source, destination } = param
    const _item = query.skills.splice(source.index, 1)[0];
    query.skills.splice(destination.index, 0, _item);
    emotionsList[queryIndex] = query;
    localStorage.setItem("emotions", JSON.stringify(emotionsList))
  };

  function clickEmotion(name){
    setSearch(name)
    const emotion = getElementbyName(name, emotionsList)[0]
    setQuery(emotion)
    setQueryIndex(1)
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <div>
    <ToastContainer />
    <div className="App">
    <h1> Which DBT skills can I use?</h1>
      <div className="search-box">
        <Autocomplete
        id="search"
        className="search"
        freeSolo
        autoComplete
        autoHighlight
        value={search}
        options={emotionOptions}
        renderInput={(params) => (
          <TextField {...params}
            onSelect={(event) => selectEmotion(event.target.value)}
            variant="outlined"
            label="How are you feeling?"
          />
        )}
      />
      {(query && queryIndex !== 0 && queryIndex !== -1) &&
        <button id="edit" type="button" onClick={() => save()}><i className={edit === false ? "fa fa-pencil" : "fa fa-cloud"} aria-hidden="true"></i></button>
      }
      </div>
      {(queryIndex === -1) &&
      <div>
        <h1> Add/Delete Emotions </h1>
        <div className="card-group">
          <div id="add-emotion">
            <input name="myInput" id="emotion-input" value={newEmotionName} onInput={e => setNewEmotionName(e.target.value)}/>
            <button id="add" type="button" onClick={() => addEmotion()}><i className="fa fa-plus" aria-hidden="true"></i></button>
          </div>
          {emotionOptions.filter((emotion) => {if (emotion !== 'add emotion'){return emotion;} return false;}).map((emotion, index) => (
              <div className="emotion-card">
                <button className="cross-emotion" onClick={() => deleteEmotion(emotion)}><i className="fa fa-times" aria-hidden="true"></i></button>
                <h2 className="emotion" onClick={() => clickEmotion(emotion)}>{emotion}</h2>
              </div>
              ))}
          </div>
        </div>
      }
      {(edit && edit !== false) &&
        <div className="add-box">
          <Autocomplete
          id="add-skill"
          className="add"
          freeSolo
          autoComplete
          autoHighlight
          options={skillOptions}
          renderInput={(params) => (
            <TextField {...params}
              onSelect={(event) => addSkill(event.target.value)}
              variant="outlined"
              label="Add a skill"
            />
          )}
        />
        </div>
      }
      {(queryIndex !== -1) && 
        <div className="card-group-flex">
          { edit !== true && !!query && query.skills.map((skill, index) => 
            <div className="card-flex" key={skill.id}>
              <p className="category" id={skill.category.charAt(0)}>{skill.category}</p>
              <h3>{skill.name}</h3>
              <p className="when"><b>When: </b>{skill.why}</p>
              <p className="what"><b>What: </b></p>
              <ul>
              {skill.what.map((part, index) => (
                <li className="why" key={index}>{part}</li>
              ))}
              </ul>
            </div>
          )}
        </div>
      }
      { (edit && edit !== false && queryIndex !== -1) &&
      <DragDropContext onDragEnd={dragEnded}>
        <Droppable droppableId="cards-wrapper">
            {(provided, snapshot) => (
              <div className="card-group" ref={provided.innerRef} {...provided.droppableProps}>
                {
                  !!query && query.skills.map((skill, index) => {return (
                    <Draggable draggableId={`card-${skill.id}`} index={index} key={skill.id}>
                      {(_provided, _snapshot) => (
                      <div id="card" className="card2" key={skill.id} ref={_provided.innerRef} {..._provided.draggableProps}>
                        <div className="grip" {..._provided.dragHandleProps}><i className="fa-solid fa-grip-vertical"></i></div>
                        <div className="card">
                          <p className="category" id={skill.category.charAt(0)}>{skill.category}</p>
                          <button className="cross" onClick={() => deleteSkill(skill.name)}><i className="fa fa-times" aria-hidden="true"></i></button>
                          <h3>{skill.name}</h3>
                          <p className="when"><b>When:</b> {skill.why}</p>
                        </div>
                      </div>
                      )}
                    </Draggable>
                  )})
                }
                {provided.placeholder}
              </div>
            )}
        </Droppable>
      </DragDropContext>
    }
    </div>
    </div>
  );
}

export default App;
